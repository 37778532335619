import { jwtDecode } from 'jwt-decode';
import { signOut } from '~/api/pharmacy.api';

export default defineNuxtRouteMiddleware((to) => {
  const isSignedIn = validTokenExists();

  if (
    !isSignedIn
    && to.path !== '/'
    && to.path !== '/signin'
    && !to.path.startsWith('/dispense')
  ) {
    return navigateTo('/');
  }

  if (isSignedIn && to.path === '/signin') {
    return navigateTo('/orders');
  }
});

function validTokenExists(): boolean {
  const accessToken = useCookie('access_token').value;

  // Check if access_token exists
  if (!accessToken) return false;

  try {
    const tokenPayload = jwtDecode<{ exp: number }>(accessToken);
    if (!!tokenPayload && tokenPayload.exp > Date.now() / 1000) {
      return true;
    }
  }
  catch {
    signOut();
    return false;
  }

  return false;
}

// refresh logic missing
