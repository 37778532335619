import Pusher from 'pusher-js';
import { loadPendingOrders } from '~/api/orders.api';

let pusher: Pusher;

export function init() {
  const runtimeConfig = useRuntimeConfig();
  const publicEnv = runtimeConfig.public;
  const url = publicEnv.API_URL;
  const token = useCookie('access_token').value;

  pusher = new Pusher(publicEnv.pusher_app_key, {
    cluster: 'eu',
    userAuthentication: {
      transport: 'ajax',
      endpoint: `${url}/pharmacy/v1/pusher/user-auth`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  });
  pusher.signin();

  const subscriber = pusher.user;
  subscriber.bind('newOrder', async () => {
    await loadPendingOrders();
  });
}

export function disconnect() {
  pusher.disconnect();
}
